//signup & login
export const SET_SIGNUP_DATA = 'SET_SIGNUP_DATA';
export const SET_CREATOR_DATA = 'SET_CREATOR_DATA';
export const SET_LOGIN_DATA = 'SET_LOGIN_DATA';
export const SET_MATERIAL_STEPS = 'SET_MATERIAL_STEPS';
export const SET_MATERIAL_STEP_1 = 'SET_MATERIAL_STEP_1';
export const SET_MATERIAL_STEP_2 = 'SET_MATERIAL_STEP_2';
export const GET_SEARCH_RESULT_DATA = 'GET_SEARCH_RESULT_DATA';
export const SET_SCHEDULE_STREAM_STEPS = 'SET_SCHEDULE_STREAM_STEPS';
export const SET_SCHEDULE_STREAM_STEP_1 = 'SET_SCHEDULE_STREAM_STEP_1';
export const SET_SCHEDULE_STREAM_STEP_2 = 'SET_SCHEDULE_STREAM_STEP_2';
export const SET_SCHEDULE_STREAM_STEP_3 = 'SET_SCHEDULE_STREAM_STEP_3';
export const SET_SCHEDULE_STREAM_STEP_4 = 'SET_SCHEDULE_STREAM_STEP_4';
export const SET_CREATOR_CLASS_FILE_OBJECT = 'SET_CREATOR_CLASS_FILE_OBJECT';
export const SET_CREATOR_CLASS_FILE_URL = 'SET_CREATOR_CLASS_FILE_URL';
export const SET_ADD_DETAILS_DATA = 'SET_ADD_DETAILS_DATA';
export const SET_OPEN_SIDEBAR = 'SET_OPEN_SIDEBAR';
export const SET_FAV_CLASS = 'SET_FAV_CLASS';
export const SET_PREVIOUS_PATH = 'SET_PREVIOUS_PATH';
export const SET_CREATOR_ID = 'SET_CREATOR_ID';
export const SET_CHAT_MESSAGE = 'SET_CHAT_MESSAGE';
export const SET_CREATOR_CLASS_ID = 'SET_CREATOR_CLASS_ID';
export const SET_JOINING_TYPE = 'SET_JOINING_TYPE';
